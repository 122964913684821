<template>
  <Layout ref="main">
    <PageHeader :title="title" :items="items" />
    <div class="row justify-content-center">
      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title font-size-18 text-dark fw-medium mb-3"><i class="uil uil-users-alt text-primary"></i> Add User to New Group 添加用户去新的群</h4>
            
            <div class="row">
              <div class="col-lg-6 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="api_id" placeholder="YOUR_API_ID" v-model="formData.apiID" :class="{'is-invalid': submitted && $v.formData.apiID.$error}" disabled="true">
                <label for="api_id">Your API ID</label>
                <div v-if="submitted && $v.formData.apiID.$error" class="invalid-feedback ">
                  <span v-if="!$v.formData.apiID.required">API ID is Required.</span>
                  <span v-if="!$v.formData.apiID.equal2A">API ID is Required.</span>
                </div>
              </div>
              </div>
              <div class="col-lg-6 mb-3">
              <div class="form-floating">
                <input type="text" class="form-control" id="hash" placeholder="YOUR_API_HASH" v-model="formData.apiHash" :class="{'is-invalid': submitted && $v.formData.apiHash.$error}" disabled="true">
                <label for="hash">Your API Hash</label>
                <div v-if="submitted && $v.formData.apiHash.$error" class="invalid-feedback ">
                  <span v-if="!$v.formData.apiHash.required">API Hash is Required.</span>
                  <span v-if="!$v.formData.apiHash.equal2B">API Hash is Required.</span>
                </div>
              </div>
              </div>
            </div>
            <router-link class="btn btn-primary me-2 ms-auto mb-1" :to="{ name: 'dashboard'}">
              <i class="uil uil-setting"></i> Go to Setting Page
            </router-link>
            <hr class="my-4">
            <div class="row">
              
              <div class="col-lg-6 h-100">
                <div class="mb-3">
                  <h4 class="card-title text-dark fw-medium mb-3 font-size-15">
                  <i class="uil uil-users-alt text-primary"></i> Users</h4>
                  <div class="form-floating mb-3" >
                    <input type="file" class="form-control" id="files" :class="{'is-invalid': submitted && $v.formData.users.$error}" :disabled="loading" style="height: 70px;" @change="handleFileSelection" ref="file">
                    <label for="files">JSON File </label>
                    <div v-if="submitted && $v.formData.users.$error" class="invalid-feedback ">
                      <span v-if="!$v.formData.users.required">JSON File is Required.</span>
                      <span v-if="fileError"> Invalid file type. Only JSON files are allowed.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 h-100">
                <div class="mb-3">
                  <h4 class="card-title text-dark fw-medium mb-3 font-size-15">
                  <i class="uil uil-edit text-primary"></i> Group Name</h4>
                  <div class="form-floating mb-3" >
                    <input type="text" class="form-control" id="media"  v-model="formData.group_name" :class="{'is-invalid': submitted && $v.formData.group_name.$error}" :disabled="loading"  style="height: 70px;">
                    <label for="media">Group Name</label>
                    <div v-if="submitted && $v.formData.group_name.$error" class="invalid-feedback ">
                      <span v-if="!$v.formData.group_name.required">Group name is required.</span>
                    </div>
                  </div>
                </div>
              </div>
         
              <div class="col-12">
              <button type="button" @click="save" class="btn btn-primary me-2" :disabled="loading || buttonLoading[0]">
              <i class="uil uil-plus-circle"></i>
              <span v-if="buttonLoading[0]"> Adding 添加中...</span>
              <span v-else> Add Users  添加用户群 </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[0]" role="status" aria-hidden="true"></span>
              </button>
              

              </div>
            </div>
        </div>
        </div>
      </div>

      <div class="col-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title font-size-18 text-dark fw-medium mb-3"><i class="uil uil-users-alt text-primary"></i> Add User to Existing Group 添加用户去现有的群</h4>
            
            <div class="row">
              <div class="col-lg-6 mb-3">
                <div class="form-floating" >
                  <input type="text" class="form-control" id="api_id" placeholder="YOUR_API_ID" v-model="formData2.apiID" :class="{'is-invalid': submitted2 && $v.formData2.apiID.$error}" disabled="true">
                  <label for="api_id">Your API ID</label>
                  <div v-if="submitted2 && $v.formData2.apiID.$error" class="invalid-feedback ">
                    <span v-if="!$v.formData2.apiID.required">API ID is Required.</span>
                    <span v-if="!$v.formData2.apiID.equal2A">API ID is Required.</span>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 mb-3">
                <div class="form-floating">
                  <input type="text" class="form-control" id="hash" placeholder="YOUR_API_HASH" v-model="formData2.apiHash" :class="{'is-invalid': submitted2 && $v.formData2.apiHash.$error}" disabled="true">
                  <label for="hash">Your API Hash</label>
                  <div v-if="submitted2 && $v.formData2.apiHash.$error" class="invalid-feedback ">
                    <span v-if="!$v.formData2.apiHash.required">API Hash is Required.</span>
                    <span v-if="!$v.formData2.apiHash.equal2B">API Hash is Required.</span>
                  </div>
                </div>
              </div>
            </div>
            <router-link class="btn btn-primary me-2 ms-auto mb-1" :to="{ name: 'dashboard'}">
              <i class="uil uil-setting"></i> Go to Setting Page
            </router-link>
            <hr class="my-4">
            <div class="row">
              
              <div class="col-lg-6 h-100">
                <div class="mb-3">
                  <h4 class="card-title text-dark fw-medium mb-3 font-size-15">
                  <i class="uil uil-users-alt text-primary"></i> Users</h4>
                  <div class="form-floating mb-3" >
                    <input type="file" class="form-control" id="files2" :class="{'is-invalid': submitted2 && $v.formData2.users.$error}" :disabled="loading" style="height: 70px;" @change="handleFileSelection" ref="file2">
                    <label for="files">JSON File </label>
                    <div v-if="submitted2 && $v.formData2.users.$error" class="invalid-feedback ">
                      <span v-if="!$v.formData2.users.required">JSON File is Required.</span>
                      <span v-if="fileError2"> Invalid file type. Only JSON files are allowed.</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 h-100">
                <div class="mb-3">
                  <h4 class="card-title text-dark fw-medium mb-3 font-size-15">
                  <i class="uil uil-edit text-primary"></i> Group ID <span class="badge bg-light text-muted font-size-12">e.g. -1001853634720</span></h4>
                  <div class="form-floating mb-3" >
                    <input type="text" class="form-control" id="media" v-model="formData2.group_id" :class="{'is-invalid': submitted2 && $v.formData2.group_id.$error}" :disabled="loading"  style="height: 70px;">
                    <label for="media">Group ID</label>
                    <div v-if="submitted2 && $v.formData2.group_id.$error" class="invalid-feedback ">
                      <span v-if="!$v.formData2.group_id.required">Group ID is required. e.g. -1001853634720</span>
                    </div>
                  </div>
                </div>
              </div>
         
              <div class="col-12">
              <button type="button" @click="save2" class="btn btn-primary me-2" :disabled="loading || buttonLoading[1]">
              <i class="uil uil-plus-circle"></i>
              <span v-if="buttonLoading[1]"> Adding 添加中...</span>
              <span v-else> Add Users  添加用户群 </span>
              <span class="spinner-border spinner-border-sm ms-2" v-if="buttonLoading[1]" role="status" aria-hidden="true"></span>
              </button>
              

              </div>
            </div>
        </div>
        </div>
      </div>



    </div>
    <Lottie :path="'animate/lottie.json'" :title="'Loading'" :info="'Please wait...'" :show="showLottie" ref="lottieFunc" />
    <Common ref="commonFunc"/>
  </Layout>
</template>
<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Lottie from "@/components/lottieView";
import Common from "@/components/common";
import axios from "axios";
import Swal from "sweetalert2";
import {required } from "vuelidate/lib/validators";
export const equal2A = (value) => {
  return value !== 'YOUR_API_ID';
}
export const equal2B = (value) => {
  return value !== 'YOUR_API_HASH';
}
/**
 * Starter page
 */
export default {
  components: { 
    Layout, 
    PageHeader, 
    Lottie,
    Common
  },
  page() {
    return {
      title: "Telegram Groups",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    }
  },
  data() {
    return {
      accessToken:'',
      accessEmail:'',
      accessPhone:'',
      accessUsername:'',
      showLottie:false,
      title: '',
      items: [
        {
          text: "OneTouch Pay",
          to: "/",
        },
        {
          text: '',
          active: true,
        },
      ],
      formData:{
        apiID: "",
        apiHash:"",
        group_name:"",
        users:null,
      },
      formData2:{
        apiID: "",
        apiHash:"",
        group_id:"",
        users:null,
      },
      fileError:false,
      fileError2:false,
      submitted:false,
      submitted2:false,
      loading:false,
      buttonLoading:[false,false,false],
    };
  },
  validations: {
    formData: {
      apiID: {
        required,
        equal2A
      },
      apiHash: {
        required,
        equal2B
      },
      group_name: {
       required
      },
      users: {
        required,
      },
    },
    formData2: {
      apiID: {
        required,
        equal2A
      },
      apiHash: {
        required,
        equal2B
      },
      group_id: {
       required
      },
      users: {
        required,
      },
    }
  },
  middleware: "authentication",
  mounted(){
    this.title = "Telegram Groups"
    this.items[1].text = "Telegram Groups"
    // this.$refs.main.changeVerticalTopBar("",true) *first value is back link and second is want set bg color?
    // this.$refs.main.changeVerticalTopBar("bill",true)
    // this.$refs.main.setShowFooterCert(false)
    // this.$refs.main.setPageTitle('title')
    this.accessToken = this.$refs.commonFunc.getToken()
    this.accessPhone = this.$refs.commonFunc.getPhone()
    this.getData();
    
  }, 
  created(){
   
  },
  methods:{
    getData(){
        this.$Progress.start();
        this.loading = true
        var bodyFormData = new FormData();
        bodyFormData.append("accessToken", this.accessToken);
        bodyFormData.append("phone",  this.accessPhone);
        axios({
            method: "post",
            url: appConfig.DemoAPI,
            data: bodyFormData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then((response)=>{
            var resData = response.data;
            if (resData.status == 200) {
              console.log(resData)
              this.reload()
            } 
            else if (resData.status == 401){
              Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
                this.$refs.commonFunc.clearData()
                this.$router.push({
                    path: "/login",
              });
            }
            else {
                Swal.fire(
                  {
                  icon: 'error',
                  title: 'Oops...',
                  html: `${resData.message}.`,
                  confirmButtonColor: '#222',
                  confirmButtonText: this.$t('siteLang.Done'),
                })
            }
            this.loading = false;
            this.$Progress.finish();
        })
        .catch((error)=> {
            this.loading = false;
            this.$Progress.finish();
            //Swal.fire("Error", error, "error");
            Swal.fire(
              {
              icon: 'error',
              title: 'Oops...',
              html: `${error}.`,
              confirmButtonColor: '#222',
              confirmButtonText: this.$t('siteLang.Done'),
            })
      });
    },
    save() {
        this.submitted = true
        this.$v.formData.$touch();
        if (this.$v.formData.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[0] = true
            
            const fileInput = document.getElementById("files");
            const file = fileInput.files[0];
            const datas = {
              phone: this.accessPhone,
              apiID: this.formData.apiID,
              apiHash: this.formData.apiHash,
              groupName: this.formData.group_name,
              filePath: "",
            };

            const reader = new FileReader();

            const sendRequest = async () => {
              try {
                await new Promise((resolve, reject) => {
                  reader.onloadend = () => {
                    datas.filePath = reader.result;
                    resolve();
                  };

                  reader.onerror = () => {
                    console.log("File reading failed.")
                    reject(new Error("File reading failed."));
                  };

                  reader.readAsDataURL(file);
                });

                const response = await 
                axios.post(
                  'https://127.0.0.1:5000/add-new-group', 
                  JSON.stringify(datas), {
                    headers: { 'Content-Type': 'application/json' },
                  });
                if(response){
                  var resData = response.data;
                  if (resData.status == 200) {
                    console.log(resData)
                    Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      html: `Users added to the group successfully!`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }
                  if (resData.status == 500) {
                    console.log(resData)
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      html: resData.message,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }
                  this.formData.group_name=""
                  this.formData.users= null
                  this.resetFileInput()

                  this.loading = false;
                  this.submitted = false
                  this.buttonLoading[0] = false
                  this.$Progress.finish();
                }
                } catch (error) {
                  this.buttonLoading[0] = false
                  this.loading = false;
                  this.$Progress.finish();
                  //Swal.fire("Error", error, "error");
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${error.message}.`,
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                }
            };

            sendRequest();
        }
    },
    save2() {
        this.submitted2 = true
        this.$v.formData2.$touch();
        if (this.$v.formData2.$invalid) {
          return;
        } else {
            this.$Progress.start();
            this.loading = true
            this.buttonLoading[1] = true
            const fileInput = document.getElementById("files2");
            const file = fileInput.files[0];
            const datas = {
              phone: this.accessPhone,
              apiID: this.formData2.apiID,
              apiHash: this.formData2.apiHash,
              groupID: this.formData2.group_id,
              filePath: "",
            };

            const reader = new FileReader();

            const sendRequest = async () => {
              try {
                await new Promise((resolve, reject) => {
                  reader.onloadend = () => {
                    datas.filePath = reader.result;
                    resolve();
                  };

                  reader.onerror = () => {
                    reject(new Error("File reading failed."));
                  };

                  reader.readAsDataURL(file);
                });

                const response = await 
                axios.post(
                  'https://127.0.0.1:5000/add-existing-group', 
                  JSON.stringify(datas), {
                    headers: { 'Content-Type': 'application/json' },
                  });
                if(response){
                  var resData = response.data;
                  if (resData.status == 200) {
                    console.log(resData)
                    Swal.fire({
                      icon: 'success',
                      title: 'Success!',
                      html: `Users added to the Existing group successfully!`,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }

                  if (resData.status == 500) {
                    console.log(resData)
                    Swal.fire({
                      icon: 'error',
                      title: 'Oops...',
                      html: resData.message,
                      confirmButtonColor: '#FA6541',
                      confirmButtonText: this.$t('siteLang.Done')
                    })
                  }

                    
                  this.formData2.group_id=""
                  this.formData2.users= null
                  this.resetFileInput()
                  
                  this.loading = false;
                  this.submitted2 = false
                  this.buttonLoading[1] = false
                  this.$Progress.finish();
                }
                } catch (error) {
                  this.buttonLoading[1] = false
                  this.loading = false;
                  this.$Progress.finish();
                  //Swal.fire("Error", error, "error");
                  Swal.fire(
                    {
                    icon: 'error',
                    title: 'Oops...',
                    html: `${error.message}.`,
                    confirmButtonColor: '#FA6541',
                    confirmButtonText: this.$t('siteLang.Done'),
                  })
                }
            };

            sendRequest();
        }
    },
    reload(){
      const data =  this.$refs.commonFunc.getFullData()
      this.formData.apiID = data.apiID
      this.formData.apiHash = data.apiHash
      this.formData2.apiID = data.apiID
      this.formData2.apiHash = data.apiHash
    },
    handleFileSelection(event) {
      console.log(event.target.id)
      if (event.target.id == 'files'){
        this.formData.users = null;
        const selectedFile = event.target.files[0];
        const isJsonFile = selectedFile.name.toLowerCase().endsWith('.json');

        if (isJsonFile) {
          this.fileError=false
          this.formData.users = selectedFile;
        } else {
          // Handle invalid file type
          // You can display an error message or reset the file selection
          console.log('Invalid file type. Only JSON files are allowed.');
          this.fileError=true
          // this.formData.file = null; // Uncomment this line to reset the file selection
        }
      }

      if (event.target.id == 'files2'){
        this.formData2.users = null;
        const selectedFile = event.target.files[0];
        const isJsonFile = selectedFile.name.toLowerCase().endsWith('.json');

        if (isJsonFile) {
          this.fileError2=false
          this.formData2.users = selectedFile;
        } else {
          // Handle invalid file type
          // You can display an error message or reset the file selection
          console.log('Invalid file type. Only JSON files are allowed.');
          this.fileError2=true
          // this.formData.file = null; // Uncomment this line to reset the file selection
        }
      }
      
    },
    resetFileInput() {
      const fileInput = document.getElementById('files');
      if (fileInput) {
        fileInput.value = '';
      }
      const fileInput2 = document.getElementById('files2');
      if (fileInput2) {
        fileInput2.value = '';
      }
    },
    // ot
  }
};
</script>
<style>

input[type="file"]::file-selector-button{
  background-color: #ededed;
  margin:0px ;
  border: none;
  border-radius: 5px;
  margin-right: 5px;
}
input[type="file"]:hover file-selector-button{
  background-color: #fff;
}
</style>